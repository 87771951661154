import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface EmojiGalleryProps {
  emojis: string[];
}

const EmojiGallery: React.FC<EmojiGalleryProps> = ({ emojis }) => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
          speed: 4000,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          speed: 5000,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          speed: 6000,
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1.5,
          speed: 7000,
        }
      }
    ]
  };

  return (
    <div className="overflow-hidden">
      <Slider {...settings}>
        {emojis.map((emoji, index) => (
          <div key={index} className="px-1">
            <div className="w-20 h-20 mx-auto">
              <img 
                src={emoji}
                alt={`Community Emoji ${index + 1}`}
                className="w-full h-full object-contain"
              />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default EmojiGallery;