import { invokeEdgeFunction } from './api'
import { supabase } from '../supabaseClient'

export const generatePrompt = async (description: string): Promise<string> => {
  try {
    const data = await invokeEdgeFunction('generatePrompt', { description })
    
    await supabase
      .from('emoji_logs')
      .insert({ user_input: description, generated_prompt: data.prompt })
    
    return data.prompt
  } catch (error) {
    throw new Error(`Failed to generate prompt: ${error instanceof Error ? error.message : 'Unknown error'}`)
  }
}

export const generateEmoji = async (prompt: string): Promise<string> => {
  try {
    const emojiData = await invokeEdgeFunction('generateEmoji', { prompt })
    
    if (!emojiData || typeof emojiData.imageUrl !== 'string') {
      throw new Error('Invalid response from generateEmoji function')
    }
    
    console.log('Generated image URL:', emojiData.imageUrl)
    
    // Send the OpenAI URL to the processOpenAIImage function
    const processedData = await invokeEdgeFunction('processOpenAIImage', { openAiImageUrl: emojiData.imageUrl })
    
    if (!processedData || typeof processedData.processedUrl !== 'string') {
      throw new Error('Invalid response from processOpenAIImage function')
    }
    
    // Log the emoji to the database
    await supabase
      .from('emoji_logs')
      .insert({ 
        user_input: prompt, 
        generated_prompt: prompt,
        image_url: processedData.processedUrl
      })
    
    return processedData.processedUrl
  } catch (error) {
    console.error('Error in generateEmoji:', error)
    throw error
  }
}