import React, { useState, useEffect } from 'react';
import { Share, Download, Copy, Check } from 'lucide-react';
import { track } from '@vercel/analytics';

interface EmojiDisplayProps {
  emojiUrl: string;
}

const EmojiDisplay: React.FC<EmojiDisplayProps> = ({ emojiUrl }) => {
  const [sharing, setSharing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [copying, setCopying] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isLinkedInOrSnapchat, setIsLinkedInOrSnapchat] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
    };
    const checkLinkedInOrSnapchat = () => {
      setIsLinkedInOrSnapchat(
        /LinkedIn/.test(navigator.userAgent) || /Snapchat/.test(navigator.userAgent)
      );
    };
    checkMobile();
    checkLinkedInOrSnapchat();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const shareEmoji = async () => {
    const message = "Check out this emoji I made on emojifactory.io!";
    
    try {
      const response = await fetch(emojiUrl);
      const blob = await response.blob();
      const file = new File([blob], 'emoji.png', { type: blob.type });

      if (navigator.canShare && navigator.canShare({ files: [file] })) {
        await navigator.share({
          text: message,
          files: [file],
        });
        track('emoji_shared', { method: 'native_share' });
        setSharing(true);
        setTimeout(() => setSharing(false), 2000);
      } else {
        track('emoji_share_fallback', { method: 'manual' });
        alert('Your browser does not support sharing files. You can long-press on the image to save or share it manually.');
      }
    } catch (err) {
      console.error('Failed to share:', err);
      alert('Failed to share the emoji. You can long-press on the image to save or share it manually.');
    }
  };

  const saveEmoji = () => {
    track('emoji_save_instructions_shown');
    if (!isLinkedInOrSnapchat) {
      alert('To save the emoji on your device, long-press the image and select "Save Image".');
    }
    setSaving(true);
    setTimeout(() => setSaving(false), 2000);
  };

  const smartCopyToClipboard = async () => {
    const message = "Check out this emoji I made on emojifactory.io!";
    const link = "https://emojifactory.io"; // Replace with the actual link
    
    try {
      const response = await fetch(emojiUrl);
      const blob = await response.blob();
      
      // Combine message and link
      const textContent = `${message}\n${link}`;
      
      // Create HTML content with image, message, and link
      const htmlContent = `
        <div>${message}</div>
        <img src="${emojiUrl}" alt="Generated Emoji" />
        <div><a href="${link}">${link}</a></div>
      `;

      // Create a clipboard item with multiple formats
      const clipboardItem = new ClipboardItem({
        'text/plain': new Blob([textContent], { type: 'text/plain' }),
        'text/html': new Blob([htmlContent], { type: 'text/html' }),
        [blob.type]: blob
      });

      await navigator.clipboard.write([clipboardItem]);
      
      track('emoji_copied_to_clipboard');
      setCopying(true);
      setTimeout(() => setCopying(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
      alert('Failed to copy the emoji. You can right-click on the image and select "Copy image" instead.');
    }
  };

  return (
    <div className="text-center">
      <img 
        src={emojiUrl} 
        alt="Generated Emoji" 
        className="mx-auto mb-6 w-48 h-48 sm:w-64 sm:h-64 object-contain"
      />
      {isLinkedInOrSnapchat && (
        <p className="mb-4 text-sm sm:text-base font-medium text-gray-700">
          To save this emoji, take a screenshot of this page.
        </p>
      )}
      <div className="flex justify-center space-x-4">
        {isMobile ? (
          <>
            <button
              onClick={shareEmoji}
              className={`
                px-6 py-3 bg-purple-600 text-white rounded-lg text-base sm:text-lg font-semibold 
                hover:bg-purple-700 transition-all duration-300 ease-in-out transform 
                flex items-center justify-center space-x-2
                ${sharing ? 'scale-105 bg-green-500' : ''}
              `}
              disabled={sharing}
            >
              <Share className={`w-5 h-5 ${sharing ? 'opacity-0' : 'opacity-100'}`} />
              <span>{sharing ? 'Shared!' : 'Share Emoji'}</span>
            </button>
            <button
              onClick={saveEmoji}
              className={`
                px-6 py-3 bg-blue-600 text-white rounded-lg text-base sm:text-lg font-semibold 
                hover:bg-blue-700 transition-all duration-300 ease-in-out transform 
                flex items-center justify-center space-x-2
                ${saving ? 'scale-105 bg-green-500' : ''}
              `}
              disabled={saving}
            >
              <Download className={`w-5 h-5 ${saving ? 'opacity-0' : 'opacity-100'}`} />
              <span>{saving ? 'Instructions Shown' : 'Save Emoji'}</span>
            </button>
          </>
        ) : (
          <button
            onClick={smartCopyToClipboard}
            className={`
              px-6 py-3 bg-blue-600 text-white rounded-lg text-base sm:text-lg font-semibold 
              hover:bg-blue-700 transition-all duration-300 ease-in-out transform 
              flex items-center justify-center space-x-2
              ${copying ? 'scale-105 bg-green-500' : ''}
            `}
            disabled={copying}
          >
            {copying ? <Check className="w-5 h-5" /> : <Copy className="w-5 h-5" />}
            <span>{copying ? 'Copied!' : 'Copy to Clipboard'}</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default EmojiDisplay;