import React from 'react';
import { motion } from 'framer-motion';

const emojis = ['😀', '😎', '🚀', '🍕'];

const FlyingEmoji: React.FC<{ emoji: string }> = ({ emoji }) => {
  return (
    <motion.div
      className="absolute text-4xl pointer-events-none"
      initial={{ y: '100vh', x: Math.random() * window.innerWidth }}
      animate={{ y: -100 }}
      transition={{ 
        duration: Math.random() * 10 + 10, 
        repeat: Infinity, 
        repeatType: 'loop',
        ease: 'linear'
      }}
    >
      {emoji}
    </motion.div>
  );
};

const FlyingEmojis: React.FC = () => {
  return (
    <>
      {emojis.map((emoji, index) => (
        <FlyingEmoji key={index} emoji={emoji} />
      ))}
    </>
  );
};

export default FlyingEmojis;