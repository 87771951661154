import React, { useState, useEffect } from 'react';
import { track } from '@vercel/analytics';

interface TextInputProps {
  onSubmit: (prompt: string) => void;
  isLoading: boolean;
}

const TextInput: React.FC<TextInputProps> = ({ onSubmit, isLoading }) => {
  const [prompt, setPrompt] = useState('');
  const [placeholder, setPlaceholder] = useState('');
  const [lastSubmission, setLastSubmission] = useState({ prompt: '', count: 0 });
  const [isLocked, setIsLocked] = useState(false);

  const suggestions = [
    "Sleepy sloth sipping coffee",
    "Cat riding a roomba",
    "Avocado proposing with a ring",
    "Pizza doing yoga",
    "Sushi rolling on a skateboard",
    "Taco Tuesday superhero",
    "Donut lifting weights",
    "Pickle wearing a top hat",
    "Sneaky ninja broccoli",
    "Unicorn eating ice cream",
    "Stressed-out pencil taking a test",
    "Burger wearing headphones",
    "Pineapple on a tropical vacation",
    "Popcorn watching a scary movie",
    "Banana in pajamas",
    "Cupcake blowing out birthday candles",
    "Smiley face with bed head",
    "Pretzel doing gymnastics",
    "Chocolate bar melting in the sun",
    "Sleepy alarm clock hitting snooze"
  ];

  useEffect(() => {
    let currentIndex = Math.floor(Math.random() * suggestions.length);
    let currentText = '';
    let isDeleting = false;

    const intervalId = setInterval(() => {
      if (!isDeleting) {
        currentText = suggestions[currentIndex].substring(0, currentText.length + 1);
        setPlaceholder(currentText);

        if (currentText === suggestions[currentIndex]) {
          isDeleting = true;
          setTimeout(() => {
            isDeleting = false;
            currentIndex = (currentIndex + 1) % suggestions.length;
          }, 1000);
        }
      } else {
        currentText = suggestions[currentIndex].substring(0, currentText.length - 1);
        setPlaceholder(currentText);
      }
    }, 50);

    return () => clearInterval(intervalId);
  }, []);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (prompt.trim() && !isLocked) {
      const trimmedPrompt = prompt.trim();
      
      if (trimmedPrompt === lastSubmission.prompt) {
        const newCount = lastSubmission.count + 1;
        if (newCount >= 3) {
          setIsLocked(true);
          alert('You have been locked out due to repeated submissions.');
          return;
        }
        setLastSubmission({ prompt: trimmedPrompt, count: newCount });
      } else {
        setLastSubmission({ prompt: trimmedPrompt, count: 1 });
      }

      track('emoji_generation', { prompt: trimmedPrompt });
      onSubmit(trimmedPrompt);
      setPrompt('');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mt-4 space-y-4">
      <div className="relative">
        <input
          type="text"
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          placeholder={isLocked ? 'Input locked due to repeated submissions' : placeholder}
          className="w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-purple-500 focus:border-purple-500"
          disabled={isLoading || isLocked}
        />
      </div>
      <button
        type="submit"
        className="w-full text-white bg-purple-600 hover:bg-purple-700 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
        disabled={isLoading || isLocked}
      >
        {isLoading ? 'Generating...' : isLocked ? 'Locked' : 'Generate'}
      </button>
    </form>
  );
};

export default TextInput;