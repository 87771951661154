import React from 'react';
import EmojiGenerator from './components/EmojiGenerator';
import { Analytics } from "@vercel/analytics/react"

const App: React.FC = () => {
  return (
    <div className="font-sans antialiased text-gray-900 bg-gray-100">
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');
          body {
            font-family: 'Poppins', sans-serif;
          }
        `}
      </style>
      <EmojiGenerator />
      <Analytics />
    </div>
    
  );
};

export default App;