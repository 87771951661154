import React, { useState, useEffect } from 'react';
import TextInput from './TextInput';
import EmojiDisplay from './EmojiDisplay';
import FlyingEmojis from './FlyingEmojis';
import EmojiGallery from './EmojiGallery';
import { generateEmoji, generatePrompt } from '../services/openAIService';

const EmojiGenerator: React.FC = () => {
  const [emojiUrl, setEmojiUrl] = useState<string | null>(null);
  const [loadingState, setLoadingState] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [generatedDescription, setGeneratedDescription] = useState<string>('');
  const [favoriteEmojis, setFavoriteEmojis] = useState<string[]>([]);

  useEffect(() => {
    const loadEmojis = async () => {
      const emojiPaths = Array.from({ length: 28 }, (_, i) => `/emojis/favorite${i + 1}.png`);
      const loadedEmojis = await Promise.all(
        emojiPaths.map(async (path) => {
          try {
            const res = await fetch(path);
            if (res.ok) return path;
            console.error(`Failed to load emoji: ${path}`);
            return null;
          } catch (error) {
            console.error(`Error loading emoji: ${path}`, error);
            return null;
          }
        })
      );
      setFavoriteEmojis(loadedEmojis.filter((emoji): emoji is string => emoji !== null));
    };

    loadEmojis();
  }, []);

  const handleGenerateEmoji = async (description: string) => {
    setEmojiUrl(null);
    setError(null);
    setGeneratedDescription(description);

    try {
      setLoadingState('Optimizing prompt');
      const prompt = await generatePrompt(description);

      setLoadingState('Generating image');
      const url = await generateEmoji(prompt);

      setEmojiUrl(url);
    } catch (err) {
      setError('Failed to generate emoji. Please try again.');
    } finally {
      setLoadingState(null);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-100 to-indigo-200 py-6 px-4 sm:py-12 sm:px-6 lg:px-8 relative overflow-hidden">
      <FlyingEmojis />
      <div className="max-w-md sm:max-w-xl md:max-w-2xl lg:max-w-4xl mx-auto">
        <div className="bg-white rounded-3xl shadow-2xl overflow-hidden">
          <div className="bg-purple-600 p-6 sm:p-8 text-white">
            <h1 className="text-3xl sm:text-4xl font-bold text-center">iOS Emoji Generator</h1>
            <p className="text-center mt-2 text-purple-200 text-sm sm:text-base">Create custom iOS-style emojis with AI magic! ✨</p>
          </div>
          <div className="p-6 sm:p-8">
            <div className="mb-6 text-red-500 text-center font-semibold">
              Project offline due to hosting costs. Thanks to thousands who created emojis! Interested in paying $0.99 or watching an ad to use? Email ethankinnan@gmail.com and I'll work on relaunching.
            </div>
            
            <TextInput onSubmit={handleGenerateEmoji} isLoading={!!loadingState} />
            
            <div className="mt-4 bg-purple-100 rounded-lg p-4">
              <h3 className="text-lg font-semibold text-purple-800 mb-2">Prompting Tips:</h3>
              <ul className="list-disc list-inside text-sm text-purple-700">
                <li>Keep descriptions simple and focused on one main subject</li>
                <li>Include an emotion or action for more expressive emojis</li>
                <li>Mention colors for key elements in your description</li>
                <li>Describe an existing emoji with alterations</li>
                <li>Avoid complex scenes or multiple characters</li>
                <li>It's not perfect - sometimes resubmit your prompt!</li>
              </ul>
            </div>

            <div className="mt-8">
              <h3 className="text-2xl font-semibold mb-4 text-center text-purple-800">Community Features:</h3>
              <EmojiGallery emojis={favoriteEmojis} />
            </div>

            {loadingState && (
              <div className="my-8 text-center">
                <div className="inline-block animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-purple-500"></div>
                <p className="mt-4 text-purple-600">{loadingState}...</p>
              </div>
            )}
            {emojiUrl && (
              <div className="mt-8">
                <h2 className="text-2xl font-semibold mb-4 text-center text-purple-800">Your Custom Emoji</h2>
                <EmojiDisplay emojiUrl={emojiUrl} />
                <p className="mt-4 text-center text-gray-600 text-sm">
                  Generated from: "{generatedDescription}"
                </p>
              </div>
            )}
            {error && <p className="text-red-500 mt-4 text-center text-sm">{error}</p>}
          </div>
        </div>
        <div className="mt-6 text-center text-gray-600 text-xs sm:text-sm">
          <p>Powered by OpenAI's DALL-E 3 and Azure Computer Vision</p>
          <p className="mt-2">
            <a 
              href="https://ethankinnan.com" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-purple-600 hover:text-purple-800 transition-colors duration-300"
            >
              Check out my other projects at ethankinnan.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default EmojiGenerator;